import dotenv from 'dotenv'
import path from 'path'

//Le fichier .env que nous utiliserons ici se trouve à la racine de notre mono repo
const envPath = path.join(__dirname, './../../../');
dotenv.config({ path: envPath + './.env' })

console.log(process.env.API_BASE_URL);

export const API_BASE_URL = process.env.API_BASE_URL || 'https://api.bookstore.andriacapai.com/v1/'
